const theme = {
  typography: {
    base: "16px",
    baseMobile: "16px"
  },
  screens: {
    mobile: "640px",
    small: "768px",
    medium: "1008px",
  },
  body: {
    contentWidth: "1000px",
    background: "#18171c",
    color: "#F5F5F5",
    transparentBackground: "rgb(24, 23, 28, .98)",
    scrollbarThumb: "#585472",
    scrollbarThumbHover: "#624c84",
    scrollbarTrack: "#242424"
  },
  nav: {
    about: "#00ffff",
    experience: "#fd4f1a",
    contact: "#ff40e3"
  },
  colors: {
    crimson: "#dc143c",
    cyan: "#00ffff",
    neonPink: "#ff00fb",
    neonRed: "#fd4f1a",
    neonGreen: "#74ee15",
    neonYellow: "#ffe700",
    neonPurple: "#ff40e3",
    lightCoral: "#f08080"
  },
  buttons: {
    small: {
      fontSize: "1em",
      height: "1.2em"
    },
    normal: {
      fontSize: "1.5em",
      height: "1.8em"
    },
    large: {
      fontSize: "2em",
      height: "2.2em"
    }
  },
  code: {
    codeColor: "#e0ffff",
    backgroundColor: "#1d1b22",
    organizationColor: "#ff56ed",
    roleColor: "#90e37c",
    roleTimeFrameColor: "#fcb6bf",
    rolesColor: "#00ffff",
    projectColor: "#eda500",
    projectsColor: "#adff2f",
    interfaceColor: "#eeeeee",
    braceColor: "rgb(255, 213, 168)",
    blockLineColor: "#808080",
    blockCollapseColor: "rgb(255, 213, 168)",
    semicolonColor: "#00ffff",
    editorBorderColor: "#808080"
  }
};

export default theme;
