import React from "react"
import theme from "../constants/theme";
import backgroundLarge from "../images/toronto-8-bit.png"
import backgroundMobile from "../images/toronto-8-bit-long-left.png"
import { ThemeProvider, createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-weight: lighter;
    font-family: "Lucida Console", Monaco, monospace;

    scrollbar-color: ${theme.body.scrollbarThumb} ${theme.body.scrollbarTrack};
    scrollbar-width: thin;
  }

  body {
    background: ${theme.body.background};
    color: ${theme.body.color};
    margin: 0px;
    overflow: hidden;
    font-size: 14px;

    background: url(${backgroundLarge});
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    @media screen and (max-width: ${props => theme.screens.mobile}) {
      background: url(${backgroundMobile});
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
    }

    @media(min-width: ${theme.body.contentWidth}) {
      font-size: 16px;
    }

    scroll-behavior: smooth;
  }

  /**
   * Scrollbar
   */
  *::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: ${theme.body.scrollbarTrack};
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: ${theme.body.scrollbarThumb};
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: ${theme.body.scrollbarThumb};
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  ul {
    list-style: none;
  }

  li {
    margin: 2em;
  }

  a, a:hover, a:active, a:visited, a:focus {
    text-decoration:none;
  }
`

const Root = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </>
)

export default Root
