const SECTIONS = {
  about: {
    label: "about",
    url: "/about",
    seo: "about"
  },
  experience: {
    label: "experience",
    url: "/experience",
    seo: "experience"
  },
  contact: {
    label: "contact",
    url: "/contact",
    seo: "contact"
  }
};

export default SECTIONS;
