import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledButton = styled.button`
  text-decoration: none;
  transition: all 0.5s;
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => (props.color ? props.color : "white")};
  border: solid 1px;
  height: ${props => (
    props.theme.buttons[props.size].height
      ? props.theme.buttons[props.size].height
      : "1.8em"
  )};
  font-size: ${props => (
    props.theme.buttons[props.size].fontSize
      ? props.theme.buttons[props.size].fontSize
      : "1em"
  )};
  ${props => (props.fullHeight ? "height: 100%;" : "")}
  ${props => (props.fulWidth ? "width: 100%;" : "")}
  &:hover,
  &:focus {
    text-shadow: 0 0 1px;
    box-shadow: 0 0 5px;
  }
`;

const StyledLink = styled(Link)`
  ${props => (props.fullHeight ? "height: 100%;" : "")}
  ${props => (props.fulWidth ? "width: 100%;" : "")}
`;

const Button = (props) => (
  <StyledLink to={props.to} {...props}>
    <StyledButton {...props}>
      {props.children}
    </StyledButton>
  </StyledLink>
);

Button.defaultProps = {
  size: "normal",
  fullHeight: false,
  fullWidth: false
};

export default Button;

